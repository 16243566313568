<script>

import api from "@/utils/api";
import {APP_SETTINGS} from "../utils/constants";

export default {
  computed: {
    APP_SETTINGS() {
      return APP_SETTINGS
    }
  },
  data() {
    return {
      show: false,
      items: null,
      title: 'Informacje / Pomoc',
      app_version: window.app_version ? window.app_version : "0.0.0",
    }
  },
  async mounted() {
    await this.getHelpData();
  },
  methods: {
    async getHelpData() {
      try {
        this.items = await api.getHelp();
      } catch (error) {
        console.error('Błąd podczas pobierania danych:', error);
      }
    },
    toggleHelp() {
      this.show = !this.show;
    },
    getCurrentYear() {
      return new Date().getFullYear();
    },
  },
}

</script>

<template>
  <div class="parent-menu">
    <button
        class="rect-button"
        @click="toggleHelp"
        title="Pokaż informacje o programie oraz pomoc"
    >
      <img src="@/assets/icons/ask.svg" alt="Pokaż informacje o programie oraz pomoc" :class="{ 'inverted': $theme.invertIcon }"/>
    </button>
  </div>

  <div v-if="show" class="help-window" ref="window">
    <div class="help-window-header">
      <span class="help-window-title">{{ title }}</span>
      <span class="close-button" @click="toggleHelp">&times;</span>
    </div>
    <div class="help-window-content">
      <div>© {{ APP_SETTINGS.APP_NAME }} Wersja {{ APP_SETTINGS.VERSION }} <br> {{ getCurrentYear() }} Incobex<br> Wszelkie prawa zastrzeżone. </div>
    </div>
    <hr>
    <div class="help-window-content" style="height: 320px">
      <ul class="link-list">
        <li v-for="item in items" :key="item.url">
          <div class="link-item">
            <a :href="item.url" target="_blank" rel="noopener noreferrer" class="links">{{ item.name }}</a><br>
            <span> -------------- </span><br>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
@import '@/assets/themes.css';

.parent-menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 4px;
}

.help-window {
  position: absolute;
  left: calc(50% - 240px);
  top: calc(50% - 240px);
  width: 640px;
  height: 480px;
  border: 1px solid var(--component-border-color);
  background-color: var(--component-background);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  z-index: 9990;
}

.help-window-header {
  padding: 10px;
  height: 24px;
  background-color: var(--buttons-active-color);
  color: var(--inverse-text-color);
  font-weight: bold;
  font-size: 18px;
}

.close-button {
  float: right;
  cursor: pointer;
  font-size: 20px;
}

.help-window-content {
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: var(--dialog-background-color);
  color: var(--primary-text-color);
}

.help-window-content::-webkit-scrollbar {
  width: 1px;
}

.help-window-content::-webkit-scrollbar-thumb {
  background-color: var(--buttons-color);
  border-radius: var(--border-radius);
}

.help-window-content::-webkit-scrollbar-track {
  background: var(--primary-background);
  border-radius: var(--border-radius);
}

.link-list {
  display: flex;
  text-decoration: none;
  list-style-type: none;
  flex-direction: column;
  gap: 10px;
  color: var(--primary-text-color);
}
.link-list li a{
  text-decoration: none;
  color: var(--links-color);
}
</style>