<template>
  <button ref="toggleButton" @click="toggleSelector" class="rect-button toggle-button-position">
    <img src="@/assets/icons/color_palette.svg" alt="color palette icon" />
    <span class="badge" id="color-badge" :style="badgeStyle">_</span>
  </button>
  <div v-if="isSelectorVisible" :style="selectorStyle" @mouseleave="toggleSelector" class="selector">
    <div class="color-grid">
      <div
          v-for="color in colors"
          :key="color"
          class="color-option"
          :style="{ backgroundColor: color }"
          @click="selectColor(color)"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColorSelector',
  emits: ['color-selected'],
  data() {
    return {
      isSelectorVisible: false,
      colors: [
        'white', 'black', 'red', 'orange', 'yellow',
        'green', 'blue', '#93A7AC', 'violet'
      ],
      actualColor: 'black',
      selectorStyle: {
        top: '0px',
        left: '0px'
      }
    };
  },
  computed: {
    badgeStyle() {
      return {
        backgroundColor: this.actualColor,
        color: this.actualColor
      };
    },
  },
  methods: {
    toggleSelector() {
      this.isSelectorVisible = !this.isSelectorVisible;
      if (!this.isSelectorVisible) return;

      const button = this.$refs.toggleButton;
      const buttonRect = button.getBoundingClientRect();
      const selectorTop = buttonRect.bottom + window.scrollY;
      const selectorLeft = buttonRect.left + window.scrollX;

      this.selectorStyle.top = `${selectorTop}px`;
      this.selectorStyle.left = `${selectorLeft-46}px`;
    },
    selectColor(color) {
      this.actualColor = color;
      this.isSelectorVisible = false;
      this.$emit('color-selected', color);
    }
  }
};
</script>

<style scoped>
@import '@/assets/themes.css';

.selector {
  position: absolute;
  margin-top: 5px;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 9999;
}

.color-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
}

.color-option {
  width: 30px;
  height: 30px;
  cursor: pointer;
  border: 1px solid #ccc;
}

.toggle-button-position {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-right: 8px !important;
  margin-top: 1px;
}

.rect-button .badge {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px 5px;
  border-radius: 50%;
  background: var(--component-background);
  color: var(--primary-text-color);
  font-size: 12px;
  line-height: 1;
  transform: translate(50%, -50%);
}
</style>